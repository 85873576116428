/* eslint-disable */
import React from 'react'
import { Autocomplete } from 'devextreme-react/autocomplete'
import DataGrid from 'devextreme-react/data-grid'
import { Column } from 'devextreme-react/data-grid'
import { Scrolling, Paging } from 'devextreme-react/data-grid'
import { Popup } from 'devextreme-react/popup'
import CellRender from './cellrender'
import { connect } from 'react-redux'

const mapStateToProps = ({ currentObj, dispatch }) => ({
  claimId: currentObj.claimId,
  policyId: currentObj.policyId,
  type: currentObj.type,
  productGroupId: currentObj.productGroupId,
  productTypeId: currentObj.productTypeId,
  dispatch,
})

class SearchComponent extends React.Component {
  static context = null

  constructor(props) {
    super(props)
    this.state = {
      searchObjects: [],
      claimId: 0,
      policyId: 0,
      type: 0,
      productGroupId: 0,
      productTypeId: 0,
      searchString: '',
      dropDownOpen: false,
      popupVisible: false,
      event: null,
      searchFocused: false,
      showSearch: false,
      fileType: 57,
    }
    this.DataGridInstance = null
    this.AutocompleteInstance = null
    this.dropDownOptions600 = {
      minWidth: 600,
    }
    this.getContext = this.getContext.bind(this)
    this.eventListener = this.eventListener.bind(this)
    this.handleValueChange = this.handleValueChange.bind(this)
    this.handleEnterKey = this.handleEnterKey.bind(this)
    this.getSubscribers = this.getSubscribers.bind(this)
    this.handleDropDownOpen = this.handleDropDownOpen.bind(this)
    this.handleDropDownClose = this.handleDropDownClose.bind(this)
    this.handlePopupHide = this.handlePopupHide.bind(this)
    this.saveDataGridInstance = this.saveDataGridInstance.bind(this)
    this.saveAutocompleteInstance = this.saveAutocompleteInstance.bind(this)
    this.focusDataGrid = this.focusDataGrid.bind(this)
    this.onRowDblClick = this.onRowDblClick.bind(this)
    this.focusAutocomplete = this.focusAutocomplete.bind(this)
    // this.subscriberTypeChanged = this.subscriberTypeChanged.bind(this)
    this.onKeyDownGrid = this.onKeyDownGrid.bind(this)
    this.onFocusedRowChanged = this.onFocusedRowChanged.bind(this)
    this.onContentReady = this.onContentReady.bind(this)
    this.onEvent = this.onEvent.bind(this)
    this.searchFocusIn = this.searchFocusIn.bind(this)
    this.searchFocusOut = this.searchFocusOut.bind(this)
  }

  render() {
    return (
      <div>
        <div className="br-1 ml-2">
          <Autocomplete
            // dataSource={this.props.streets}
            // stylingMode="outlined"
            searchExpr="Name"
            minSearchLength={1}
            width={210}
            height={30}
            valueExpr="Name"
            onInitialized={this.saveAutocompleteInstance}
            onEnterKey={this.handleEnterKey}
            onValueChanged={this.handleValueChange}
            onOpened={this.handleDropDownOpen}
            showClearButton={true}
            onClosed={this.handleDropDownClose}
            onFocusIn={this.searchFocusIn}
            onFocusOut={this.searchFocusOut}
          ></Autocomplete>
        </div>
        <Popup
          visible={this.state.popupVisible}
          onHiding={this.handlePopupHide}
          onShown={this.focusDataGrid}
          dragEnabled={false}
          hideOnOutsideClick={true}
          showCloseButton={false}
          showTitle={false}
          fullScreen={false}
          focusStateEnabled={true}
          title="Trovati"
          width={window.innerWidth}
          height={window.innerHeight}
          showClearButton={true}
        >
          <DataGrid
            dataSource={this.state.searchObjects}
            keyExpr="Id"
            showBorders={false}
            mode="single"
            focusedRowEnabled={true}
            cacheEnabled={false}
            autoNavigateToFocusedRow={false}
            focusStateEnabled={true}
            height={window.innerHeight - 50}
            onInitialized={this.saveDataGridInstance}
            onContentReady={this.onContentReady}
            onRowDblClick={this.onRowDblClick}
            onFocusedRowChanged={e => {
              this.onFocusedRowChanged(e)
            }}
            onKeyDown={this.onKeyDownGrid}
            wordWrapEnabled={true}
          >
            <Scrolling mode="virtual" />
            {/*<Scrolling mode="infinite" />*/}
            {/* <Scrolling mode="normal" /> */}
            <Paging defaultPageSize={window.innerHeight / 44} />
            <Column
              dataField="Id"
              caption="Id"
              dataType="Number"
              visible={false}
              sortIndex={0}
              sortOrder="asc"
              width={50}
            />
            <Column
              dataField="ClaimId"
              caption="ClaimId"
              dataType="Number"
              visible={false}
              sortIndex={0}
              sortOrder="asc"
              width={50}
            />
            <Column
              dataField="PolicyId"
              caption="PolicyId"
              dataType="Number"
              visible={false}
              sortIndex={0}
              sortOrder="asc"
              width={50}
            />
            <Column
              dataField="ProductGroupId"
              caption="ProductGroupId"
              dataType="Number"
              visible={false}
              sortIndex={0}
              sortOrder="asc"
              width={50}
            />
            <Column
              dataField="ProductTypeId"
              caption="ProductTypeId"
              dataType="Number"
              visible={false}
              sortIndex={0}
              sortOrder="asc"
              width={50}
            />
            <Column
              dataField="ObjectType"
              caption="Tipologia Elemento"
              dataType="string"
              cellRender={CellRender}
              width={110}
            />
            <Column
              dataField="ClaimNumber"
              caption="Numero Sinistro"
              dataType="number"
              width={90}
              cssClass="account"
            />
            <Column
              dataField="PracticeNumber"
              caption="Numero Pratica/Finanziamento"
              dataType="string"
              // width={340}
            />
            <Column
              dataField="FirstName"
              caption="Nome"
              dataType="string"
              // width={270}
            />
            <Column
              dataField="SecondName"
              caption="Cognome"
              dataType="string"
              // width={270}
            />
            <Column
              dataField="Name"
              caption="Ragione Sociale"
              dataType="string"
              // width={270}
            />
            <Column
              dataField="TaxCode"
              caption="Codice Fiscale"
              dataType="string"
              // width={270}
            />
            <Column
              dataField="VatNumber"
              caption="Partita IVA"
              dataType="string"
              // width={270}
            />
            <Column
              dataField="ProductGroup"
              caption="Ramo"
              dataType="string"
              // width={270}
            />
            <Column
              dataField="ProductType"
              caption="Prodotto"
              dataType="string"
              // width={270}
            />
            <Column
              dataField="PolicyNumber"
              caption="N° certificato assicurativo/Polizza"
              dataType="string"
              // width={270}
            />
          </DataGrid>
        </Popup>
      </div>
    )
  }

  componentDidMount() {
    document.addEventListener('keyup', this.eventListener, false)
    this.setState({ subscriberType: this.props.type }, () => {})
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.eventListener, false)
  }

  getContext(e) {
    this.context = e
  }

  eventListener(e) {
    this.setState({ event: e }, () => {})
    this.onEvent(e)
  }

  handleValueChange(e) {
    if (e.value != null)
      this.setState(
        {
          searchString: e.value
            .toString()
            .replace(`\\`, '&')
            .replace('/', '&')
            .replace('/', '&'),
        },
        () => {},
      )
  }

  handleEnterKey(e) {
    if (!this.state.dropDownOpen) {
      if (this.state.searchFocused) {
        this.getSubscribers()
        this.setState({ popupVisible: true }, () => {})
        this.focusDataGrid()
      }
    }
  }

  handleDropDownOpen(e) {
    this.setState({ dropDownOpen: true }, () => {})
  }

  handleDropDownClose(e) {
    this.setState({ dropDownOpen: false }, () => {})
  }

  handlePopupHide(e) {
    this.setState({ popupVisible: false }, () => {})
  }

  async getSubscribers() {
    const { searchString } = this.state
    const data = await search(searchString)
    this.setState({ subscribers: data }, () => {})
  }

  saveDataGridInstance(e) {
    this.DataGridInstance = e.component
  }

  saveAutocompleteInstance(e) {
    this.AutocompleteInstance = e.component
  }

  focusDataGrid() {
    this.DataGridInstance.focus()
  }

  onContentReady(e) {
    e.component.focus()
  }

  focusAutocomplete() {
    this.AutocompleteInstance.focus()
  }

  // subscriberTypeChanged() {
  //   this.setState({subscriberType: this.state.subscriberType === 0 ? 1 : 0}, () => {
  //   })
  // }

  onKeyDownGrid(e) {
    if (e.event.key === 'Escape') {
      this.setState({ popupVisible: false }, () => {})
    }
    if (e.event.key === 'Enter') {
      this.onRowDblClick()
      this.setState({ popupVisible: false }, () => {})
    }
  }

  onFocusedRowChanged(e) {
    const dataRow = e.row && e.row.data
    this.setState({ claimId: dataRow.claimId })
    this.setState({ account: dataRow.Account })
  }

  onRowDblClick() {
    this.props.dispatch({
      type: 'claim/CHANGE_CLAIM',
      payload: {
        claimId: this.state.claimId,
        policyId: this.state.policyId,
        type: this.state.type,
        productGroupId: this.state.productGroupId,
        productTypeId: this.state.productTypeId,
      },
    })
    this.setState({ popupVisible: false }, () => {})
  }

  onEvent(e) {
    if (e.keyCode === 27) {
      if (this.state.popupVisible) {
        this.setState({ popupVisible: false }, () => {})
      }
      this.focusAutocomplete()
    }
    if (e.keyCode === 32 && e.ctrlKey === true) {
      this.setState({ event: e, popupVisible: !this.state.popupVisible }, () => {})
    }
    // if (e.keyCode === 113) {
    //   this.setState({subscriberType: this.state.subscriberType === 0 ? 1 : 0}, () => {
    //   })
    // }

    this.setState({ event: e }, () => {}) // обязательно event обновлять
  }

  searchFocusIn(e) {
    this.setState({ searchFocused: true }, () => {})
  }

  searchFocusOut(e) {
    this.setState({ searchFocused: false }, () => {})
  }
}

export default connect(mapStateToProps)(SearchComponent)
