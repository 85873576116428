import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import references from './references/sagas'
import search from './search/sagas'
import currentObj from './currentObj/sagas'
import currentItem from './currentItem/sagas'

export default function* rootSaga() {
  yield all([menu(), settings(), user(), references(), search(), currentObj(), currentItem()])
}
