
import actions from './actions'

const initialState = {
  data: [],
  changes: [],
  editRowKey: null,
  isLoading: false,
}

export default function currentItemReducer(state = initialState, action) {

  // let newData;

  switch (action.type) {
    case actions.SAVING_SUCCESS:
      // newData = applyChanges(state.data, [payload.change], { keyExpr: 'OrderID' });

      return {
        ...state,
        data: null,
        changes: [],
        editRowKey: null,
        isLoading: false,
      };
    case actions.SAVING_CANCEL:
      return {
        ...state,
        changes: [],
        editRowKey: null,
      };
    case actions.SET_CHANGES:
      return {
        ...state,
        changes: action.payload,
      };
    case actions.SET_EDIT_ROW_KEY:
      return {
        ...state,
        editRowKey: action.payload,
      };
    case actions.FETCH_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case actions.FETCH_PENDING:
    case actions.SAVING_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case actions.FETCH_ERROR:
    case actions.SAVING_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
