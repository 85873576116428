import store from 'store'
import React from "react";
import { notification } from "antd";
import apiIdentityClient from '../apiclients/identity'

export async function getLoginList() {
  return apiIdentityClient
    .get('/user/getloginlist')
    .then(response => {
      if (response) {
        return response.data
      }
      return undefined
    })
    .catch(err => console.log(err))
}

export async function login(user, password) {
  //  store.set('accessToken', 'accessToken')
  //  return true

  return apiIdentityClient
    .post('/auth/login', {
      user,
      password,
    })
    .then(response => {
      if (response) {
        const { accessToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return response.data
      }
      return false
    })
    .catch(err => {
      notification.error({
        message: 'Errore di autorizzazione',
        description: (
          <div>
            Servizio non disponibile.
          </div>
        ),
      })
      console.log(err)
    })
}

export async function currentAccount() {
  return apiIdentityClient
    .get('/auth/account')
    .then(response => {
      if (response) {
        const { accessToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function logout() {
  return apiIdentityClient
    .get('/auth/logout')
    .then(() => {
      store.remove('accessToken')
      return true
    })
    .catch(err => console.log(err))
}

export async function changePassword(password) {
  return apiIdentityClient
    .post(`/user/changepassword`, { password })
    .then(response => {
      if (response) {
        return true
      }
      return false
    })
    .catch(err => {
      notification.error({
        message: 'Errore cambia password',
        description: (
          <div />
        ),
      })
      console.log(err)
    })
}
