import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { searchClaimsPolicies } from 'core/services/api/claims'
import actions from './actions'


export function* LOAD({ payload: { params } }) {
  yield put({
    type: 'search/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(searchClaimsPolicies, params)
  if (response) {
    yield put({
      type: 'search/SET_STATE',
      payload: { items: response, params }
    })
  }
  else {
    yield put({
      type: 'search/SET_STATE',
      payload: { items: null, params }
    })
    notification.info({
      message: 'Info',
      description: 'Sinistri/polizze non trovati',
    })
  }

  yield put({
    type: 'search/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD, LOAD)])
}
