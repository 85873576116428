const actions = {
  FETCH_PENDING: 'currentItem/FETCH_PENDING',
  FETCH_SUCCESS: 'currentItem/FETCH_SUCCESS',
  FETCH_ERROR: 'currentItem/FETCH_ERROR',

  SAVING_PENDING: 'currentItem/SAVING_PENDING',
  SAVING_SUCCESS: 'currentItem/SAVING_SUCCESS',
  SAVING_ERROR: 'currentItem/SAVING_ERROR',
  SAVING_CANCEL: 'currentItem/SAVING_CANCEL',

  SAVE_CHANGE: 'currentItem/SAVE_CHANGE',
  SAVE_DOCUMENT_CHANGE: 'currentItem/SAVE_DOCUMENT_CHANGE',
  SAVE_CHANGES: 'currentItem/SAVE_CHANGES',
  SET_CHANGES: 'currentItem/SET_CHANGES',
  SET_EDIT_ROW_KEY: 'currentItem/SET_EDIT_ROW_KEY',

  SET_STATE: 'currentItem/SET_STATE',
}

export default actions
