import axios from 'axios'
import store from 'store'
import { notification } from 'antd'

const host = store.get('identityUrl')

const apiIdentityClient = axios.create({
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
  baseURL: `${host}/api/v1/`,
})

apiIdentityClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.AccessToken = accessToken
  }
  return request
})

apiIdentityClient.interceptors.response.use(undefined, error => {
  // Errors handling
  const { response } = error
  const { data } = response
  if (data) {
    notification.warning({
      message: data.title,
    })
  }
})

export default apiIdentityClient
