import axios from 'axios'
import store from 'store'
import { notification } from 'antd'
import apiIdentityClient from '../identity'

const host = store.get('inarUrl') === undefined ? '' : store.get('inarUrl')

const apiClient = axios.create({
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
  baseURL: `${host}/v1/`,
  // timeout: 1000,
  // headers: { 'X-Custom-Header': 'foobar' }
})

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.AccessToken = accessToken
  }
  return request
})

apiIdentityClient.interceptors.response.use(
  response => {
    if (response.status === 401) {
      notification.warning({ message: 'You are not authorized' })
    }
    return response
  },
  error => {
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data)
    }
    return Promise.reject(error.message)
  },
)

export default apiClient
