/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { injectIntl } from 'react-intl'
import SearchComponent from './search_component.js'

let searchInput = null

const Search = ({ intl: { formatMessage } }) => {
  const [showSearch, setShowSearch] = useState(false)
  const [visibleInput, setVisibleInput] = useState(true)
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  })

  const showVisibleInput = () => {
    setVisibleInput(false)
  }

  const showLiveSearch = () => {
    setShowSearch(true)
    setVisibleInput(true)
    console.log(showSearch)
    setTimeout(() => {
      //searchInput.focus()
    }, 100)
  }

  const changeSearchText = e => {
    setSearchText(e.target.value)
  }

  const hideLiveSearch = () => {
    //searchInput.blur()
    setShowSearch('')
    setSearchText('')
  }

  const handleKeyDown = event => {
    if (showSearch) {
      const key = event.keyCode.toString()
      if (key === '27') {
        hideLiveSearch()
        setVisibleInput(false)
      }
    }
  }

  const handleNode = node => {
    searchInput = node
  }

  return <SearchComponent />
}

export default injectIntl(Search)
