import localeAntd from 'antd/es/locale/en_US'

const messages = {
  'topBar.issuesHistory': 'Issues History',
  'topBar.projectManagement': 'Azioni',
  'topBar.typeToSearch': 'Ricerca...',
  'topBar.findPages': 'Find pages...',
  'topBar.actions': 'Azioni',
  'topBar.status': 'Stato',
  'topBar.profileMenu.hello': 'Ciao',
  'topBar.profileMenu.billingPlan': 'Billing Plan',
  'topBar.profileMenu.role': 'Role',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Phone',
  'topBar.profileMenu.editProfile': 'Edit Profile',
  'topBar.profileMenu.logout': 'Logout',
  'topBar.profileMenu.changepassword': 'Cambia password',
}

export default {
  locale: 'en-US',
  localeAntd,
  messages,
}
