import React from 'react'
import style from './style.module.scss'

const Footer = () => {
  return (
    <div className={style.footer}>
      <div className={style.footerInner}>
        <p className="mb-0">
          Copyright © 2000-2024 AllRisks srl
        </p>
      </div>
    </div>
  )
}

export default Footer
