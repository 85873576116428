import store from 'store'

export default function getConfiguration() {
  fetch('/Home/Configuration')
    .then(response => response.json())
    .then(data => {
      const { identityUrl, inarUrl } = data
      store.set('identityUrl', identityUrl)
      store.set('inarUrl', inarUrl)
    })
}
