import { all, call, put, takeEvery } from "redux-saga/effects";
import actions from "./actions";
import { addOrUpdateItem, addOrUpdateItems } from "../../services/api/claims";

export function* SAVE_CHANGE({ payload }) {
  const { entity, claimId, changes, file } = payload

  if (entity && changes && changes.type) {
    yield put({ type: actions.SAVING_PENDING })

    try {
      // const claimId = yield select(state => state.currentObj.claimId)
      let item = {}
      if (claimId !== null) {
        item = { ...changes.data, Id: changes.key, ClaimId: claimId }
      } else {
        item = { ...changes.data, Id: changes.key }
      }

      if (entity === 'ClaimDocument') {
        changes.type += 'doc'
        const formData = new FormData();
        if (file != null) {
          formData.append('files', file);
        }
        formData.append('item', JSON.stringify(item));
        item = formData
      }

      const success = yield call(addOrUpdateItem, changes.type, entity, item)
      if (success) {
        yield put({
          type: actions.SAVING_SUCCESS,
          payload: { change: changes.data, },
        })
        if (claimId !== null) {
          yield put({
            type: 'currentObj/CHANGE_CLAIM',
            payload: { claimId },
          })
        }
      }
      return success
    } catch (err) {
      yield put({ type: actions.SAVING_ERROR })
      throw err
    }
  } else {
    yield put({ type: actions.SAVING_CANCEL })
    return null
  }
}

export function* SAVE_CHANGES({ payload }) {
  const { entity, claimId, changes } = payload

  if (entity && changes) {
    yield put({ type: actions.SAVING_PENDING })

    try {
      const success = yield call(addOrUpdateItems, entity, changes)
      if (success) {
        yield put({
          type: actions.SAVING_SUCCESS,
          payload: { change: changes.data, },
        })
        yield put({
          type: 'currentObj/CHANGE_CLAIM',
          payload: { claimId },
        })
      }
      return success
    } catch (err) {
      yield put({ type: actions.SAVING_ERROR })
      throw err
    }
  } else {
    yield put({ type: actions.SAVING_CANCEL })
    return null
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SAVE_CHANGE, SAVE_CHANGE),
    takeEvery(actions.SAVE_CHANGES, SAVE_CHANGES),
  ])
}
