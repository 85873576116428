import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import * as auth from 'core/services/identity'
import actions from './actions'

const mapAuthProviders = {
  jwt: {
    login: auth.login,
    currentAccount: auth.currentAccount,
    logout: auth.logout,
    changePassword: auth.changePassword,
  },
}

export function* LOGIN({ payload }) {
  const { email, password } = payload

  yield put({ type: 'user/SET_STATE', payload: { loading: true, }, })

  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].login, email, password)

  if (success) {
    yield put({ type: 'user/LOAD_CURRENT_ACCOUNT', })
    yield history.push('/')
    notification.success({
      message: 'Login',
      description: 'Hai effettuato l\'accesso con successo!',
    })
  }

  if (!success) {
    yield put({ type: 'user/SET_STATE', payload: { loading: false, }, })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({ type: 'user/SET_STATE', payload: { loading: true, }, })

  const { authProvider } = yield select(state => state.settings)
  const response = yield call(mapAuthProviders[authProvider].currentAccount)

  if (response) {
    const { id, email, name, role, claims } = response
    const isInternal = (role === 'Administrator')
      || (role === 'Claim Manager')
      || (role === 'Claim Handler')
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id,
        name,
        email,
        role,
        isInternal,
        claims,
        authorized: true,
      },
    })
    yield put({ type: 'references/LOAD' })
  }

  yield put({ type: 'user/SET_STATE', payload: { loading: false, }, })
}

export function* LOGOUT() {
  const { authProvider } = yield select(state => state.settings)

  yield call(mapAuthProviders[authProvider].logout)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      claims: [],
      authorized: false,
      loading: false,
    },
  })
}

export function* CHANGE_PASSWORD({ payload }) {
  const { password } = payload
  yield put({ type: 'user/SET_STATE', payload: { loading: true, }, })

  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].changePassword, password)

  if (success) {
    notification.success({
      message: 'Password',
      description: 'Password è stato cambiato!',
    })
    yield put({ type: 'user/LOGOUT', })
    yield history.push('/')
  }

  yield put({ type: 'user/SET_STATE', payload: { loading: false, }, })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.CHANGE_PASSWORD, CHANGE_PASSWORD),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
