import qs from 'qs'
import apiClient from '../../apiclients/claims'

export async function getReferencesAll() {
  return apiClient
    .get(`claims/references/getall`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getReferenceList(name, where, order, include) {
  return apiClient
    .get(`claims/references/${name}/all`, {
      params: { where, order, include },
      paramsSerializer: params => qs.stringify(params, { encode: false }),
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}
