import store from 'store'
import actions from './actions'

const initialState = {
  claimId: store.get('claimId') === undefined ? '' : store.get('claimId'),
  policyId: store.get('policyId') === undefined ? '0' : store.get('policyId'),
  policyInarId: store.get('policyInarId') === undefined ? '0' : store.get('policyInarId'),
  policyAppId: store.get('policyAppId') === undefined ? '0' : store.get('policyAppId'),
  claim: {},
  policy: {},
  // type: store.get('type') === undefined ? 0 : store.get('type'),
  // productGroupId: store.get('productGroupId') === undefined ? '0' : store.get('productGroupId'),
  // productTypeId: store.get('productTypeId') === undefined ? '0' : store.get('productTypeId'),
  // account: store.get('account') === undefined ? 64 : store.get('account'),
  // idSubscriberSearch: store.get('idSubscriberSearch') === undefined ? 'CA228136-6758-4E59-95C7-B6051B417E94' : store.get('idSubscriberSearch'),

  loading: false,
}

export default function currentObjReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
