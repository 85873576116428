export default async function getMenuData() {
  return [
    {
      category: true,
      title: 'Sinistro',
    },
    {
      title: 'Gestione sinistri',
      key: 'sinistri',
      icon: 'fe fe-image',
      children: [
        {
          title: 'Dashboard',
          key: 'claimsDashboard',
          icon: 'fe fe-home',
          url: '/claims/dashboard',
        },
        {
          title: 'Ricerca',
          key: 'claimsSearch',
          url: '/claims/search',
        },
        {
          title: 'Sinistro',
          key: 'claimsEdit',
          url: '/claims/claim',
        },
      ],
    },
    /*    {
      category: true,
      title: 'Polizza',
    },
    {
      title: 'Polizze',
      key: 'apps',
      icon: 'fe fe-database',
      children: [
        {
          title: 'Dashboard',
          key: 'dashboardPolicies',
          icon: 'fe fe-home',
          url: '/dashboard/policies',
        },
        {
          title: 'Previntivatore RCP',
          key: 'appsProfile',
          url: '/apps/profile',
        },
        {
          title: 'Ricerca',
          key: 'ecommerceOrders',
          url: '/ecommerce/orders',
        },
      ],
    }, */
    /*    {
      category: true,
      title: 'Analisi',
    },
    {
      title: 'Analisi',
      key: 'analisi',
      icon: 'fe fe-bookmark',
      url: '/ui-kits/antd',
    },
    {
      category: true,
      title: 'Anagrafica',
    },
    {
      title: 'Ricerca anagrafica',
      key: 'customerSearch',
      icon: 'fe fe-menu',
      url: '/claims/customer',
    }, */
  ]
}
