import actions from './actions'

const initialState = {
  ClaimApprovalState: [],
  ClaimDescriptionType: [],
  ClaimExpirationState: [],
  ClaimExpirationType: [],
  ClaimPaymentType: [],
  ClaimRecoveryState: [],
  ClaimRecoverySource: [],
  ClaimReserveType: [],
  ClaimState: [],
  ClaimSubState: [],
  ClaimType: [],
  CommunicationType: [],
  InstallmentReasonType: [],
  InstallmentState: [],
  InsuredQualification: [],
  HistoryField: [],
  PeriodicityRate: [],
  RecipientType: [],
  ResponsibleActivity: [],
  ResponsibleDocumentState: [],
  ResponsibleDocumentType: [],
  ResponsibleQualification: [],
  ResponsibleType: [],
}

export default function referencesReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
