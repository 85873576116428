import apiClient from '../../apiclients/claims'

export async function getClaim(id) {
  return apiClient
    .get(`claims/${id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getNewClaim(claimParams) {
  return apiClient
    .post(`claims/new`, claimParams)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function addClaim(item) {
  return apiClient
    .post(`claims`, item)
    .then(response => {
      return response
    })
    .catch(err => console.log(err))
}

export async function updateClaim(item) {
  return apiClient
    .put(`claims`, item)
    .then(response => {
      return response
    })
    .catch(err => console.log(err))
}

export async function addOrUpdateItem(type, entity, item) {
  switch (type) {
    case 'insert':
      return apiClient
        .post(`claims/${entity}`, item)
        .then(response => { return response })
        .catch(err => console.log(err))
    case 'update':
      return apiClient
        .put(`claims/${entity}`, item)
        .then(response => { return response })
        .catch(err => console.log(err))
    case 'insertdoc':
    case 'updatedoc': {
      const typeId = type === 'insertdoc' ? 1 : 2
      return apiClient
        .post(`claims/document/${typeId}`, item, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then(response => {
          return response
        })
        .catch(err => console.log(err))
    }
    default:
      return null
  }
}

export async function addOrUpdateItems(entity, item) {
  return apiClient
    .post(`claims/items/${entity}`, item)
    .then(response => {
      return response
    })
    .catch(err => console.log(err))
}

export async function generetePlan(counterpart) {
  return apiClient
    .post(`claims/plan`, counterpart)
    .then(response => {
      return response
    })
    .catch(err => console.log(err))
}

export async function getClaimDocumentFile(id) {
  return apiClient
    .get(`claims/document/${id}`, { headers: {
    'Content-Type': 'application/json',}, responseType: 'blob',})
    .then(response => {
      if (response) { return response.data }
      return false
    })
    .catch(err => console.log(err))
}

export async function getClaimReportFile(id, format) {
  return apiClient
    .get(`claims/${id}/format/${format}`, { headers: {
        'Content-Type': 'application/json',}, responseType: 'blob',})
    .then(response => {
      if (response) { return response }
      return false
    })
    .catch(err => console.log(err))
}

export async function getClaimPaymentFile(id, paymentid, type, format) {
  return apiClient
    .get(`claims/${id}/payment/${paymentid}/type/${type}/format/${format}`, { headers: {
        'Content-Type': 'application/json',}, responseType: 'blob',})
    .then(response => {
      if (response) { return response }
      return false
    })
    .catch(err => console.log(err))
}

export async function getClaimPaymentZipFile(paymentdate, type, format) {
  return apiClient
    .get(`claims/paymentsepa/paymentdate/${paymentdate}/type/${type}/format/${format}`, { headers: {
        'Content-Type': 'application/zip',}, responseType: 'blob',})
    .then(response => {
      if (response) { return response }
      return false
    })
    .catch(err => console.log(err))
}
export async function getPolicy(id, idInar, idApp) {
  return apiClient
    .get(`claims/policy/${id}/${idInar}/${idApp}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function searchClaimsPolicies(params) {
  return apiClient
    .post(`claims/search/`, params)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function searchCustomers(pattern) {
  return apiClient
    .get(`claims/customer/search/${pattern}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getExpirations() {
  return apiClient
    .get(`claims/expirations`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getApprovals() {
  return apiClient
    .get(`claims/approvals`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getClaimDocuments() {
  return apiClient
    .get(`claims/documents`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getUsers() {
  return apiClient
    .get(`claims/users`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getPayments() {
  return apiClient
    .get(`claims/payments`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}
