/* eslint-disable */
import React from 'react'

export default function CellRender(cellData) {
  return (
    <div
      className={
        cellData.value === 'Контрагент'
          ? 'current-value text-danger'
          : cellData.value === 'Лицевой счет'
          ? 'current-value text-danger'
          : cellData.value === 'Точка учета'
          ? 'current-value text-primary'
          : cellData.value === 'Счетчик'
          ? 'current-value text-success'
          : 'current-value'
      }
    >
      {cellData.value}
    </div>
  )
}
