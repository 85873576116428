import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import references from './references/reducers'
import search from "./search/reducers";
import currentObj from './currentObj/reducers'
import currentItem from './currentItem/reducers'


export default history =>
  combineReducers({
    router: connectRouter(history),
    menu,
    settings,
    references,
    user,
    search,
    currentObj,
    currentItem,
  })
