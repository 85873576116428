import { all, takeEvery, put, call } from 'redux-saga/effects'
import { getReferencesAll } from 'core/services/api/references'
import actions from './actions'

export function* LOAD() {
  yield put({
    type: 'references/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(getReferencesAll)
  if (response) {
    yield put({
      type: 'references/SET_STATE',
      payload: response,
    })
  }

  yield put({
    type: 'references/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD, LOAD)])
}
