import { all, takeEvery, put, call } from 'redux-saga/effects'
import store from 'store'
import { notification } from 'antd'
import actions from './actions'
import { getClaim, updateClaim } from '../../services/api/claims'

export function* CHANGE_CLAIM({ payload: { claimId } }) {
  let claim = {}
  if (claimId != null) {
    claim = yield call(getClaim, claimId)
  }
  yield put({ type: 'currentObj/SET_STATE', payload: { claim, claimId } })
  store.set('claimId', claimId)
}

export function* UPDATE_CLAIM({ payload: { claim } }) {
  const success = yield call(updateClaim, claim)
  if (success) {
    yield put({
      type: 'currentObj/CHANGE_CLAIM',
      payload: { claimId: claim.Id },
    })
  } else {
    notification.error({
      message: 'Errore',
      description: 'Errore salvatagio sinistro!',
    })
  }
}

export function* CHANGE_POLICY({
  payload: { policyId, policyInarId, policyAppId, type, productGroupId, productTypeId, claimId },
}) {
  yield put({
    type: 'currentObj/SET_STATE',
    payload: {
      policyId,
      policyInarId,
      policyAppId,
      type,
      productGroupId,
      productTypeId,
      claimId,
    },
  })
  store.set('policyId', policyId)
  store.set('policyInarId', policyInarId)
  store.set('policyAppId', policyAppId)
  store.set('type', type)

  if (claimId != null) {
    let claim = {}

    claim = yield call(getClaim, claimId)
    yield put({ type: 'currentObj/SET_STATE', payload: { claim, claimId } })
  }
  store.set('claimId', claimId)
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CHANGE_CLAIM, CHANGE_CLAIM),
    takeEvery(actions.UPDATE_CLAIM, UPDATE_CLAIM),
    takeEvery(actions.CHANGE_POLICY, CHANGE_POLICY),
    CHANGE_CLAIM({ payload: { claimId: store.get('claimId') } }),
  ])
}
